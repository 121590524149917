import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'

import mainHeader from '../../images/solutions/rpaMain.png'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { FaqSection } from '../../components/faq'
import { rpaList } from '../../utils/customersList'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FaChevronRight } from 'react-icons/fa'
import VideoModal from '../../components/videoModal'

import rpaDemo from '../../video/rpa-demo.mp4'

interface MethodologyItem {
  count: number
  title: string
  description: JSX.Element
}

const getMethodologyItems = (
  handleClick: React.Dispatch<React.SetStateAction<boolean>>
): MethodologyItem[] => [
  {
    count: 1,
    title: 'Licenciamento',
    description: (
      <>
        Licenciamento de software RPA, incluindo designer e orquestrador de
        robôs assistidos e não assistidos. Tecnologia simples, ágil e intuitiva
        para automação robótica de processos. Sistema low code e de custo
        competitivo.{' '}
        <button
          type="button"
          className="btn-link"
          onClick={() => handleClick(true)}
        >
          Veja demonstração
        </button>
      </>
    ),
  },
  {
    count: 2,
    title: 'Melhoria',
    description: (
      <>
        Contempla a descoberta e exploração de processos com potencial de
        automação robótica. É feita uma análise de viabilidade técnica e
        econômica e após, os processos são otimizados para que possam ser de
        fato, automatizados.
      </>
    ),
  },
  {
    count: 3,
    title: 'Implementação',
    description: (
      <>
        Esta etapa contempla o levantamento de requisitos, programação e deploy
        dos bots. Um gestor de implementação acompanha todo o processo para que
        os robôs sejam entregues de forma ágil, dentro de um cronograma
        previamente estabelecido.
      </>
    ),
  },
  {
    count: 4,
    title: 'Performance',
    description: (
      <>
        A gestão de performance RPA contempla além da otimização e sustentação
        das automações, um dashboard de estatísticas, apresentando execuções bem
        sucedidas, falhas, volumetria, ociosidade, produtividade, dentre outros
        indicadores relevantes para a operação.
      </>
    ),
  },
]

const faqItems = [
  {
    question: 'O que é automação robótica de processos?',
    answer:
      'Do acrônimo, RPA, a automação robótica de processos é uma tecnologia que permite que atividades operacionais, digitais e repetitivas sejam automatizadas por meio de softwares robôs. Os robôs são capazes de imitar as ações de um usuário, como pressionar teclas, emitir respostas, transferir dados entre aplicativos e executar transações.',
  },
  {
    question: 'Quais as principais aplicações para RPA?',
    answer:
      'É possível automatizar rotinas de trabalho em diversas áreas de negócio, tais como: processamento de notas fiscais, baixa de boletos bancários, fechamento de folha de pagamento, cálculo de impostos, cadastro de produtos, geração de relatórios, dentre outras atividades.',
  },
  {
    question: 'Quais os benefícios da automação robótica de processos?',
    answer:
      'Com a automação RPA é possível reduzir custos, ganhar velocidade no processamento de informações, minimizar erros e proporcionar maior segurança no tratamento de dados. Além disso, é uma tecnologia que requer mínima infraestrutura necessária, contando apenas com uma estação de trabalho conectada à internet.',
  },
  {
    question: 'Como a High Jump pode ajudar na jornada de automação?',
    answer:
      'Apoiamos desde empresas que estão iniciando a jornada RPA à empresas que já possuem uma solução para automação, mas que estão buscando migrar de ferramenta, por qualquer razão. Oferecemos uma solução completa que inclui a licença do software e equipe especializada para implementação.',
  },
]

const RpaPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Layout location={location}>
      <SEO title="High Jump - Automação Robótica de Processos" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={styles.mainTitle}>
              <h1>Automação de processos RPA</h1>
              <p>
                Consultoria empresarial especializada em automação robótica de
                processos (RPA). Eleve a produtividade, minimize erros e reduza
                custos. Invista o capital humano disponível em atividades mais
                estratégicas.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Otimizar processos organizacionais por meio da transformação
                  digital, utilizando ferramentas para automação robótica de
                  tarefas.
                </p>
              </div>

              <div className={styles.info}>
                <div className={styles.item}>
                  <h1>Hi-Discovery</h1>
                  <p>
                    Descoberta de oportunidades de automação RPA e análise de
                    viabilidade. Definição de quais processos automatizar,
                    recursos necessários, retorno sobre o investimento e payback
                    estimados.{' '}
                    <AnchorLink
                      to={`/automacao-robotica-de-processos/discovery`}
                    >
                      Saiba mais
                    </AnchorLink>
                  </p>
                </div>

                <div className={styles.item}>
                  <h1>Hi-Dev</h1>
                  <p>
                    Desenvolvimento e validação de bots em ambiente de produção.
                    Metodologia ágil para entrega de robôs de baixa, média e
                    alta complexidade. A etapa contempla análise de requisitos,
                    programação, teste e validação de bots.{' '}
                    <AnchorLink to={`/automacao-robotica-de-processos/dev`}>
                      Saiba mais
                    </AnchorLink>
                  </p>
                </div>

                <div className={styles.item}>
                  <h1>Hi-Performance</h1>
                  <p>
                    Acompanhamento, otimização e atendimento de chamados de
                    suporte e sustentação de automações para sua plena execução.
                    Gestão da performance de robôs para alavancar a
                    produtividade com controle de estatísticas.{' '}
                    <AnchorLink
                      to={`/automacao-robotica-de-processos/performance`}
                    >
                      Saiba mais
                    </AnchorLink>
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.viabilitySection}>
              <span>
                Quer saber se seu processo pode ser automatizado com RPA?
              </span>
              <AnchorLink to={`/simulador-viabilidade-rpa`}>
                Simule a viabilidade agora
                <FaChevronRight />
              </AnchorLink>
            </div>

            <MethodologySection
              items={getMethodologyItems(setIsOpen)}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para
                  implementação da tecnologia RPA em sua empresa."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={rpaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Consultoria para otimização de processos</h2>
                  <p>Evitando que erros ou redundâncias sejam automatizados</p>
                </div>
                <div className={styles.div2}>
                  <h2>Treinamento prático para as áreas de negócio</h2>
                  <p>Com o conteúdo preparatório para implementação do RPA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Estudo de viabilidade de projeto</h2>
                  <p>
                    Contemplando custo, retorno sobre o investimento e payback
                  </p>
                </div>
                <div className={styles.div4}>
                  <h2>Mão de obra especializada e exclusiva</h2>
                  <p>
                    Alocação de profissionais certificados e dedicação exclusiva
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />

          <FaqSection items={faqItems} />

          <Footer />

          <VideoModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            videoUrl={rpaDemo}
          />
        </main>
      </div>
    </Layout>
  )
}

export default RpaPage
