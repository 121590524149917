import { Dialog } from '@headlessui/react'
import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import { FiX } from 'react-icons/fi'
import './styles.scss'

interface VideoModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  videoUrl: string
}

const VideoModal: React.FC<VideoModalProps> = ({
  isOpen,
  setIsOpen,
  videoUrl,
}) => {
  const [playing, setPlaying] = useState(false)
  const playerRef = useRef<ReactPlayer>(null)

  useEffect(() => {
    if (isOpen) {
      setPlaying(true)
    } else {
      setPlaying(false)
      playerRef.current?.seekTo(0)
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="video-modal"
    >
      <div className="video-modal--backdrop" aria-hidden="true" />
      <Dialog.Panel className="video-modal--panel">
        <div className="video-modal--header">
          <Dialog.Title className="video-modal--title">
            RPA em ação
          </Dialog.Title>
          <FiX
            className="video-modal--close-icon"
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className="video-modal--divider" />
        <div className="video-modal--video-container">
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            controls
            width="100%"
            height="100%"
            playing={playing}
          />
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}

export default VideoModal
